import React from 'react';
import {
  Button, Alert, NavLink,
} from 'reactstrap';
import CountUp from 'react-countup';
import ReactVisibilitySensor from 'react-visibility-sensor';

import './homeContainer.css';
import FooterContainer from '../footerContainer/FooterContainer';

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  updateSize = () => {
    this.setState({ isStaticBanner: window.innerWidth })
  }

  handleChangePath = (url) => {
    this.props.history.push(url);
  }

  activateSuccessMsg = (message) => {
    this.setState({ message: message })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)
  }

  render() {
    let { message } = this.state
    return (
      <div id="HomeMainContainer" className="container" >
        {message &&
          <Alert style={{ width: "calc(100% - 47px)", position: "absolute", zIndex: "100" }} color="success">
            {message}
          </Alert>
        }
        <div id="homeSectionOne">
          <img src="/images/home/HomeBanner.jpg" alt="" width="100%" height="auto" />

          <div id="homeSectionMajorImg" className="row">
            <div className=" col-4" onClick={() => { this.handleChangePath("/CompanyProducts/Hospitals") }}>
              <div className="homeCtsIcons">
                <img src="/images/home/icons/MED.png" alt="" />
              </div>
              <NavLink className="section-tow-header"><p style={{ color: "#fff" }}>Hospital Information System</p></NavLink>
            </div>
            <div className=" col-4" onClick={() => { this.handleChangePath("/CompanyProducts/SupermarketsMinimarkets") }}>
              <div className="homeCtsIcons">
                <img src="/images/home/icons/SUP.png" alt="" />
              </div>
              <NavLink className="section-tow-header"><p style={{ color: "#fff" }}>Supermarkets</p></NavLink>
            </div>
            <div className=" col-4" onClick={() => { this.handleChangePath("/CompanyProducts/ChainStores") }}>
              <div className="homeCtsIcons">
                <img src="/images/home/icons/ERP.png" alt="" />
              </div>
              <NavLink className="section-tow-header"><p style={{ color: "#fff" }}>Chain Stores</p></NavLink>
            </div>
          </div>
          {/*increment visitor */}
          {/* <div id="homeSectioIntelXeon" className="row" style={{ overflow: "hidden", marginBottom: "20px", cursor: "pointer" }} onClick={() => { this.handleChangePath("/IntelXeon") }} >
            <div id="rotateBanner"  >
              <img src="/images/home/intel.png" alt="" />
            </div>
          </div> */}
        </div>

        {/* increment visitor */}
        {/* <div id="homeSectioIntelXeonUnder" className="row" style={{ overflow: "hidden", marginBottom: "20px", cursor: "pointer" }} onClick={() => { this.handleChangePath("/IntelXeon") }} >
          <div id="rotateBanner"  >
            <img src="/images/home/intel.png" alt="" />
          </div>
        </div> */}

        <div id="homeSectionMajor" className="row">
          <div className="col-sm-12" style={{ cursor: "Pointer" }} onClick={() => { this.handleChangePath("/CompanyProducts/Hospitals") }}>
            <div className="homeCtsIcons">
              <img src="/images/home/icons/MED.png" alt="" />
            </div>
            <div className="section-tow-header"><div>Hospital Information System</div></div>
          </div>
          <div className="col-sm-12" style={{ cursor: "Pointer" }} onClick={() => { this.handleChangePath("/CompanyProducts/SupermarketsMinimarkets") }}>
            <div className="homeCtsIcons">
              <img src="/images/home/icons/SUP.png" alt="" />
            </div>
            <div className="section-tow-header"><div>Supermarkets</div></div>
          </div>
          <div className="col-sm-12" style={{ cursor: "Pointer" }} onClick={() => { this.handleChangePath("/CompanyProducts/ChainStores") }}>
            <div className="homeCtsIcons">
              <img src="/images/home/icons/ERP.png" alt="" />
            </div>
            <div className="section-tow-header"><div>Chain Stores (ERP)</div></div>
          </div>
        </div>



        {/* <div id="homeSectionThree" className="row section-three">
          <div className="col-12">
            <h3>Together We Do Better <img src="/images/home/icons/Hands.png" alt="" /></h3>
          </div>
        </div> */}

        <div id="homeSectionFour" className="row">
          <div className="col-12">
            <div id="homeSectionFourText">
              <h2>WHO WE ARE</h2>
              <p>It started off with a simple idea: It’s not just about the Solution; it’s about the support and long-lasting client relations!
                This is why we developed a complete Distribution and Service Infrastructure for the fastest available service in the market. At C.T.Serv,
                our dedication for our customers drives who we are and what we do. During our years of professional performance, we managed to win the confidence of our client base.</p>
              <p>At C.T.Serv, we believe that Together we do Better! Together with our clients we can dream, and Together with our team we can make those dreams come true!</p>
              <Button onClick={() => { this.handleChangePath("/CompanyOverview") }} color="primary" style={{ borderRadius: "60px", paddingRight: "20px", paddingLeft: "20px" }}>Meet US</Button>
            </div>
          </div>
          <div className="col-12" >
            <img id="HomeAboutUsImg" src="/images/home/HomeAboutUs.jpg" alt="" width="100%" height="auto" />
          </div>
        </div>


        <div id="homeSectionTow" className="row">
          <div className="col-sm-12 col-lg-3">
            <div className="homeCtsIcons">
              <img src="/images/home/icons/support.png" alt="" />
            </div>
            <div className="section-tow-header">24/7 SUPPORT</div>
            <p>
              One request for support outside office hours will activate all our technical departments for your software, system, and technical inquiries.
            </p>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="homeCtsIcons">
              <img src="/images/home/icons/afterSalesSupport.png" alt="" />
            </div>
            <div className="section-tow-header">AFTER SALES SUPPORT</div>
            <p>
              The after sales support policy of CTServ is a key element in our success. 93 highly technical employees are always available to serve you.
            </p>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="homeCtsIcons">
              <img src="/images/home/icons/producutSpecialists.png" alt="" />
            </div>
            <div className="section-tow-header">PRODUCT SPECIALISTS</div>
            <p>
              Our years of experience have created expert product specialists in their industry. This constitutes our key strength.
            </p>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="homeCtsIcons">
              <img src="/images/home/icons/integratedCommunication.png" alt="" /></div>
            <div className="section-tow-header">INTEGRATED COMMUNICATION</div>
            <p>
              We integrated the evolution in communication in our applications. We offer our clients the opportunity to run remote sites with the lowest running cost and highest security.
            </p>
          </div>
        </div>

        <div id="homeSectionFive" className="row">
          <div className="col-sm-12 col-lg-3" style={{ maxHeight: "300px" }}>
            <div className="counterImg">
              <CountUp end={38} duration={2.75} redraw={true}>
                {({ countUpRef, start }) => (
                  <ReactVisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </ReactVisibilitySensor>
                )}
              </CountUp>
              <h5>Major Hospitals</h5>
            </div>
            <img src="/images/home/hospitals.jpg" alt="" width="100%" height="auto" className="center" />
          </div>
          <div className="col-sm-12 col-lg-3" style={{ maxHeight: "300px" }}>
            <div className="counterImg">
              <span>+</span>
              <CountUp end={980} duration={2.75} redraw={true}>
                {({ countUpRef, start }) => (
                  <ReactVisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </ReactVisibilitySensor>
                )}
              </CountUp>
              <h5>POS at Supermarkets</h5>
            </div>
            <img src="/images/home/supermarkets.jpg" alt="" width="100%" height="auto" className="center" />
          </div>
          <div className="col-sm-12 col-lg-3" style={{ maxHeight: "300px" }}>
            <div className="counterImg">
              <CountUp end={45} duration={2.75} redraw={true}>
                {({ countUpRef, start }) => (
                  <ReactVisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </ReactVisibilitySensor>
                )}
              </CountUp>
              <h5>Major Chain Stores</h5>
            </div>
            <img src="/images/home/chain-stores.jpg" alt="" width="100%" height="auto" className="center" />
          </div>
          <div className="col-sm-12 col-lg-3" style={{ maxHeight: "300px" }}>
            <div className="counterImg">
              <CountUp end={550} duration={2.75} redraw={true}>
                {({ countUpRef, start }) => (
                  <ReactVisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </ReactVisibilitySensor>
                )}
              </CountUp>
              <h5>Corporate Accounts</h5>
            </div>
            <img src="/images/home/corporate-accounts.jpg" alt="" width="100%" height="auto" className="center" />
          </div>
        </div>

        <Button onClick={() => { this.handleChangePath("/ContactUs") }} color="primary" style={{ margin:"20px auto", borderRadius: "60px", paddingRight: "20px", paddingLeft: "20px", marginRight:"5px" }}>Contact Us</Button>
        <Button onClick={() => { this.handleChangePath("/privacy-Policy") }} color="primary" style={{ margin:"20px auto", borderRadius: "60px", paddingRight: "20px", paddingLeft: "20px" }}>Privacy Policy</Button>

        <div id="AppFooterContainer">
          <FooterContainer />
        </div>

      </div>
    )
  }

}

export default HomeContainer;

