import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import "./mainNavigator.css"

class MainNavigator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleChangePath = (url) => {
    if (document.getElementById("companyProductsContainer"))
      document.getElementById("companyProductsContainer").scrollTo(0, 0)
    window.scrollTo(0, 0)
    this.toggle()
    this.props.history.push(url);
  }

  render() {
    return (
      <div id="MainNavContainer">
        <div id="NavContainerCentered">
          <Navbar color="light" light expand="md">


            <NavLink style={{ cursor: "Pointer" }} onClick={() => { this.handleChangePath("/Home") }} >
              <img src="/images/Logo.png" alt="" width="240" height="50" />
            </NavLink>
            <NavbarToggler onClick={this.toggle} style={{ float: "right" }} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>


                <NavLink style={{ cursor: "Pointer" }} className="navText" onClick={() => { this.handleChangePath("/Home") }} >
                  HOME
                </NavLink>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="navText" nav caret>
                    SOFTWARE
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyProducts/Hospitals") }}>Hospitals (HIS)</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyProducts/MedicalCenter") }}>Medical Center</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyProducts/SupermarketsMinimarkets") }}>Supermarkets</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyProducts/ChainStores") }}>Chain Stores (ERP)</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyProducts/PayrolHR") }}>PayrolHR</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="navText" nav caret>
                    HARDWARE
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyProducts/NuTechHardware") }}>NuTech Hardware</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* increment visitor */}
                {/* <NavLink style={{ cursor: "Pointer" }} className="navText" onClick={() => { this.handleChangePath("/INTELXEON") }} >
                  INTEL
                </NavLink> */}

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="navText" nav caret>
                    ABOUT US
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => { this.handleChangePath("/CompanyOverview") }}>Company Overview</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/Activities") }}>Gallery</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/Careers") }}>Careers</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/ContactUs") }}>Contact Us</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/CtsPrivacyPolicy") }}>App Privacy Policy</DropdownItem>
                    {/* <DropdownItem onClick={() => { this.handleChangePath("/CtsPrivacyPolicyHISServices") }}>HIS Services Privacy Policy</DropdownItem> */}
                    <DropdownItem onClick={() => { this.handleChangePath("/WhatsNew") }}>What's New</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink href="https://www.linkedin.com/company/computer-technology-&amp;-services-c-t-serv-" target="_blank" ><i style={{ color: "#0077b5", fontSize: "24px" }} className="fa fa-linkedin-square" aria-hidden="true"></i></NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink href="" target="_blank" ><i style={{ color: "#0077b5", fontSize: "24px" }} className="fa fa-facebook-square" aria-hidden="true"></i></NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink href="https://www.instagram.com/c.t.serv/" target="_blank" ><i style={{ color: "#0077b5", fontSize: "24px" }} className="fa fa-instagram" aria-hidden="true"></i></NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

export default MainNavigator;