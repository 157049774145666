// import logo from './logo.svg';
import './App.css';
import MainNavigator from './appContainers/mainNavigator/MainNavigator';
// import FooterContainer from './appContainers/footerContainer/FooterContainer';
import { Router, Switch, Route } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { createHashHistory } from 'history';

import HomeContainer from "./appContainers/homeContainer/HomeContainer";
import CompanyProducts from "./appContainers/companyProducts/CompanyProducts";
import Activities from './appContainers/activities/Activities';
import Careers from './appContainers/careers/Careers';
import ContactUs from './appContainers/contactUs/ContactUs';
import CompanyOverview from './appContainers/companyOverview/CompanyOverview';
import PrivacyPolicy from './appContainers/privacyPolicy/privacyPolicy';
import PetmartPrivacyPolicy from './appContainers/petmartPrivacyPolicy/petmartPrivacyPolicy';
import CtsPrivacyPolicy from './appContainers/ctsPrivacyPolicy/CtsPrivacyPolicy';
import CtsPrivacyPolicyHISServices from './appContainers/ctsPrivacyPolicyHISServices/CtsPrivacyPolicyHISServices';
import WhatsNew from './appContainers/contactUs copy 2/WhatsNew';   
// import IntelXeon from './appContainers/intelXeon/IntelXeon';

// const history = createHashHistory({
//   getUserConfirmation: (message, callback) => callback(window.confirm(message))
// });

const history = createHashHistory()
const App = () => (
  <div className="App">

    {history.location.pathname === "/privacypolicy/lecharcutierrewards"
      ?
      <PrivacyPolicy />
      :
      history.location.pathname === "/privacypolicy/petmartslb"
      ?
      <PetmartPrivacyPolicy />
      :
      <>
        <div id="SpinnerMaincontainer">
          <Spinner id="Spinnercontainer" color="primary" />
        </div>
        <div id="AppHeaderContainer">
          <MainNavigator history={history} />
        </div>
        <div id="AppBodyContainer" style={{ height: "calc(100% - 80px)" }} >
          <Router history={history}>
            <Switch>
              <Route path="/Home" component={HomeContainer} />
              <Route path="/CompanyProducts" component={CompanyProducts} />
              {/* <Route path="/INTELXEON" component={IntelXeon} /> */}
              <Route path="/Careers" component={Careers} />
              <Route path="/Activities" component={Activities} />
              <Route path="/ContactUs" component={ContactUs} />
              <Route path="/privacy-Policy" component={CtsPrivacyPolicy} />
              <Route path="/CtsPrivacyPolicy" component={CtsPrivacyPolicyHISServices} />
              <Route path="/WhatsNew" component={WhatsNew} />
              <Route path="/CompanyOverview" component={CompanyOverview} />
              <Route path="/privacypolicy/lecharcutierrewards" exact component={PrivacyPolicy} />
              <Route path="/privacypolicy/petmartslb" exact component={PetmartPrivacyPolicy} />
              <Route path="/" exact component={HomeContainer} />
              {/* <Redirect path="/" /> */}
            </Switch>
          </Router>
        {/* <div id="AppFooterContainer">
          <FooterContainer />
        </div> */}
        </div>
      </>
    }
  </div>
)

export default App;
